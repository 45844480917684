import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import styles from "./footer.module.css"
import Text from "../Typography/Text"
import Divider from "../Divider"
import Padding from "../Layout/Padding"
import Heading from "../Typography/Heading"

function Footer(props) {
  const {
    facebookLink,
    instagramLink,
    facebookGroupLink,
    developerLink,
    privacyPolicyLink
  } = useStaticQuery(query).site.siteMetadata

  return (
    <footer className={styles.footer}>
      <Padding medium safeBottom>
        <Heading headingSize={4}>Pripojte sa</Heading>
        <Text><OutboundLink style={{ color: '#312ADB' }} href={facebookGroupLink}>facebook skupina histaminikov</OutboundLink></Text>

        {instagramLink &&
          <>
            <Divider spaceOnly micro />
            <Text><OutboundLink style={{ color: '#312ADB' }} href={instagramLink}>instagram</OutboundLink></Text>
          </>
        }
        <Divider spaceOnly micro />
        <Text><OutboundLink style={{ color: '#312ADB' }} href={facebookLink}>facebook stránka</OutboundLink></Text>
        <Divider spaceOnly small />
        <Text small><OutboundLink style={{ color: '#312ADB' }} href={privacyPolicyLink}>Spracovanie osobných údajov</OutboundLink></Text>
        <Text small><OutboundLink style={{ color: '#312ADB' }} href={developerLink}>Stránka vývojára</OutboundLink></Text>
        <Divider spaceOnly small />
        <Text centered small>Vyrobené na Slovensku <span className={styles.flagSk} style={{ display: 'inline-block', width: '16px', height: '11px' }}/></Text>
      </Padding>
    </footer>
  )
}

Footer.propTypes = {

}

const query = graphql`
query FooterLinks {
  site {
    siteMetadata {
      facebookLink
      instagramLink
      facebookGroupLink
      developerLink
      privacyPolicyLink
    }
  }
}
`


export default Footer

