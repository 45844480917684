import React from 'react'
import Heading from "../Typography/Heading"
import Row from "../Layout/Row"
import HitlistLogo from "../../images/hitlist-logo.svg"
import Divider from "../Divider"
import MediaQuery from "../MediaQuery"
import StoreButtons from "../StoreButtons"
import Text from "../Typography/Text"
import IphoneFrame from "../IphoneFrame"

import HitlistGif from "../../images/hitlist-preview.gif"

function HitlistPresentation(props) {
  return (
    <section>
      <Row style={{ justifyContent: 'space-between' }}>
        <div style={{ alignSelf: 'center', margin: '0 auto' }}>
          <img style={{
            display: 'block',
            margin: '0 auto'
          }} src={HitlistLogo} alt="Hitlist ikona" />

          <Heading centered headingSize={1}>Hitlist</Heading>

          <h2 style={{
            fontSize: '35px', fontFamily: 'Open Sans', fontWeight: 300, lineHeight: 1, textAlign: 'center', marginBottom: '20px'
          }}>
            Appka pre <br />
          histaminikov
        </h2>

          <StoreButtons />

          <Text small centered style={{ maxWidth: '270px', margin: '10px auto', fontStyle: 'italic', fontFamily: 'Open Sans', fontWeight: '300' }}>
            Aplikácia Hitlist neslúži ako náhrada odbornej pomoci.
            Ak máte zdravotné problémy, najprv vyhľadajte odbornú zdravotnú pomoc.
            Nepreberáme žiadnu zodpovednosť za bezprostredné ani následné škody akéhokoľvek druhu.
          </Text>
        </div>


        <div style={{ margin: '0 auto' }}>
          <MediaQuery hide={'desktop'}>
            <Divider spaceOnly small />
          </MediaQuery>
          {/* <img style={{ maxWidth: '100%',  }} src={hitlistPreview} /> */}
          <IphoneFrame zoom={0.74} style={{ display: 'block', margin: '0 auto' }}>
            <img src={HitlistGif} style={{ maxWidth: '100.3%', display: 'block', margin: '0 auto' }}
              alt="Prezentácia aplikácie pomocou videa." />
          </IphoneFrame>
        </div>
      </Row>

      <MediaQuery hide={'desktop'}>
        <Divider spaceOnly small />
      </MediaQuery>
    </section>
  )
}

HitlistPresentation.propTypes = {

}

export default HitlistPresentation

