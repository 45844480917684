import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Row from "../Layout/Row"

import downloadButtonAppStore from "../../images/appstore.svg"
import downloadButtonPlayStore from "../../images/download-button-playstore.png"

function StoreButtons(props) {

  const {
    appStoreLink, playStoreLink
  } = useStaticQuery(query).site.siteMetadata

  return (
    <Row rowMobile>
      <OutboundLink href={appStoreLink} style={{ textDecoration: 'none', display: 'block' }}>
        <img style={{ width: '140px' }} src={downloadButtonAppStore} alt="Stiahnuť na App Store (iOS)" />
      </OutboundLink>

      <OutboundLink href={playStoreLink} style={{ textDecoration: 'none', display: 'block' }}>
        <img style={{ width: '140px' }} src={downloadButtonPlayStore} alt="Stiahnuť na Google Play Store (Android)" />
      </OutboundLink>
    </Row>
  )
}

StoreButtons.propTypes = {

}

const query = graphql`
query StoreButtonsLinks {
  site {
    siteMetadata {
      appStoreLink
      playStoreLink
    }
  }
}
`

export default StoreButtons

