import React from 'react'
import styles from "./row.module.css"

function Row(props) {
  const { rowMobile } = props
  
  return (
    <div
      className={`${rowMobile ? '' : styles.flexColumn}`}
      style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' , ...props.style}}>
      {props.children}
    </div>
  )
}

Row.propTypes = {

}

export default Row
