import React from 'react'
import PropTypes from 'prop-types'

function Container(props) {
  return (
    <div style={{ maxWidth: props.wide ? '900px' : '650px', margin: '0 auto' }}>
      {props.children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.any.isRequired
}

export default Container

