import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "./heading.module.css"

export default class Heading extends Component {
  static propTypes = {
    headingSize: PropTypes.number.isRequired,
    centered: PropTypes.bool,
    white: PropTypes.bool
  }





  render() {
    const { centered, white } = this.props

    const style = `
      ${centered ? styles.centered : ''}
      ${white ? styles.white : ''}
    `

    switch (this.props.headingSize) {
      case 1:
        return <h1 className={`${styles.h1} ${style}`}>{this.props.children}</h1>
      case 2:
        return <h2 className={`${styles.h2} ${style}`}>{this.props.children}</h2>
      case 3:
        return <h3 className={`${styles.h3} ${style}`}>{this.props.children}</h3>
      case 4:
        return <h4 className={`${styles.h4} ${style}`}>{this.props.children}</h4>
      case 5:
        return <h5 className={`${styles.h5} ${style}`}>{this.props.children}</h5>
      case 6:
        return <h6 className={`${styles.h6} ${style}`}>{this.props.children}</h6>
      default:
        return <h1 className={`${styles.h1} ${style}`}>{this.props.children}</h1>
    }
  }
}
