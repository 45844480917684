import React from 'react'
import PropTypes from 'prop-types'
import styles from './padding.module.css'

const _switch = (switchable, cases, _default) => {
  for (const [key, val] of Object.entries(cases)) {
    if (switchable[key]) {
      return val;
    }
  }
  return _default
}

function Padding(props) {
  const style = _switch(props, {
    "small": styles.padding10,
    "medium": styles.padding15,
    "large": styles.padding20
  }, styles.padding20)

  const safeSideStyle = _switch(props, {
    "safeTop": styles.safeTop,
    "safeLeft": styles.safeLeft,
    "safeRight": styles.safeRight,
    "safeBottom": styles.safeBottom
  }, "")

  return (
    <div className={`${safeSideStyle} ${style}`}>
      {props.children}
    </div>
  )
}

Padding.propTypes = {
  safeBottom: PropTypes.bool
}

export default Padding

