import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from "./text.module.css"

export default class text extends Component {
  static propTypes = {
    white: PropTypes.bool,
    centered: PropTypes.bool,
    space: PropTypes.bool,
    widerLineHeight: PropTypes.bool
  }

  render() {
    const { white, centered, small, blue, space, widerLineHeight, ...rest } = this.props
    const style = `
      ${blue ? styles.blue : ''}
      ${white ? styles.white : ''} 
      ${centered ? styles.centered : ''}
      ${small ? styles.small : ''}
      ${space ? styles.space : ''}
      ${widerLineHeight ? styles.widerLineHeight: ''}
    `

    return (
      <p className={`${styles.p} ${style}`} {...rest}>
        {this.props.children}
      </p>
    )
  }
}
