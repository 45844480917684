import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './section.module.css'

export default class Section extends Component {
  static propTypes = {
    body: PropTypes.element,
    chin: PropTypes.any,
    title: PropTypes.element
  }

  render() {
    const { title } = this.props

    return (
      <div>
        {this.props.title}
        <section style={{ ...title ? { marginTop: 0 } : null }} className={this.props.chin ? styles.sectionWithChin : styles.section}>
          {this.props.body}
        </section>

        {this.props.chin &&
          <div className={styles.sectionChin}>
            {this.props.chin}
          </div>
        }
      </div>
    )
  }
}
