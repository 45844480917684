
import React from 'react'

import styles from "./IphoneFrame.module.css"

function IphoneFrame(props) {

  const zoom = props.zoom ? props.zoom : 1.0

  return (
    <div style={{ zoom: zoom, MozTransformStyle: `scale(${zoom})`, MozTransformOrigin: '0 0', ...props.style }} >
      <div className={styles.marvelDevice + ' ' + styles.iphoneX} style={{ margin: '0 auto', display: 'block' }}>
        <div className={styles.notch}>
          <div className={styles.camera}></div>
          <div className={styles.speaker}></div>
        </div>
        <div className={styles.topBar}></div>
        <div className={styles.sleep}></div>
        <div className={styles.bottomBar}></div>
        <div className={styles.volume}></div>
        <div className={styles.overflow}>
          <div className={styles.shadow + ' ' + styles.shadowTr}></div>
          <div className={styles.shadow + ' ' + styles.shadowTl}></div>
          <div className={styles.shadow + ' ' + styles.shadowBr}></div>
          <div className={styles.shadow + ' ' + styles.shadowBl}></div>
        </div>
        <div className={styles.innerShadow}></div>
        <div className={styles.screen}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

IphoneFrame.propTypes = {

}

export default IphoneFrame

