import React from 'react'
import PropTypes from 'prop-types'
import styles from "./divider.module.css"

function Divider(props) {
  const style = `${props.white ? styles.white : ''}`

  if (props.spaceOnly) {
    return <div className={`
      ${styles.spaceOnly}
      ${props.small ? styles.small : ''}
      ${props.micro ? styles.micro : ''}
    `}></div>
  }

  return (
    <hr className={`${styles.hr} ${style}`} />
  )
}

Divider.propTypes = {
  white: PropTypes.bool,
  spaceOnly: PropTypes.bool
}

export default Divider

