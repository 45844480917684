import React from 'react'
import PropTypes from 'prop-types'
import styles from "./MediaQuery.module.css"

function MediaQuery(props) {

  /* Possible values of `prop.hide`
  mobile
  tablet
  desktop
  which gets style from styles object
  */
  const classes = Array.isArray(props.hide) ?
    props.hide.filter(q => q !== undefined).map(q => styles[q]).join(' ')
  :
    styles[props.hide]

  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

MediaQuery.propTypes = {
  hide: PropTypes.string.isRequired
}

export default MediaQuery
